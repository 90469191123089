<template>
  <v-footer dark padless width="100%">
    <v-card class="grey darken-3 white--text text-center" flat tile width="100%">
      <v-row align="start" justify-sm="center" no-gutters>
        <v-col cols="12" sm="5">
          <v-card-text>
            <kurcc-brand :icon-size="iconSize" icon-color="white" text-color-class="text--white"/>
          </v-card-text>
        </v-col>
        <v-col class="text-start" sm="2">
          <v-card-title>
            {{ $vuetify.lang.t('$vuetify.general.footer.menu') }}
          </v-card-title>
          <v-card-text>
            <v-card-text class="py-0">
              <v-btn :to="{name: 'about'}" text>
                {{ $vuetify.lang.t('$vuetify.general.navItems.about') }}
              </v-btn>
            </v-card-text>
            <v-card-text class="py-0">
              <v-btn :to="{name: 'contact'}" text>
                {{ $vuetify.lang.t('$vuetify.general.navItems.contactUs') }}
              </v-btn>
            </v-card-text>
            <v-card-text class="py-0">
              <v-btn :to="{name: 'search'}" text>
                {{ $vuetify.lang.t('$vuetify.general.navItems.sites') }}
              </v-btn>
            </v-card-text>
            <v-card-text class="py-0">
              <v-btn :to="{name: 'site'}" text>
                {{ $vuetify.lang.t('$vuetify.general.becomeAVendor') }}
              </v-btn>
            </v-card-text>
          </v-card-text>
        </v-col>
        <v-col class="text-start" cols="auto" sm="5">
          <v-card-title>
            {{ $vuetify.lang.t('$vuetify.general.footer.contact') }}
          </v-card-title>
          <v-card-text class="text-start">
            <v-card-subtitle class="py-2">
              <v-icon>mdi-phone-in-talk-outline</v-icon>
              (4321) 0987-654-320
            </v-card-subtitle>
            <v-card-subtitle class="py-2">
              <v-icon>mdi-email-outline</v-icon>
              <a class="ms-1" href="mailto:support@hotel.com">support@hotel.com</a>
            </v-card-subtitle>
            <v-card-subtitle class="py-2">
              <v-icon>mdi-google-maps</v-icon>
              33 Jaja Park Rd, Yellow, USA
            </v-card-subtitle>
          </v-card-text>
        </v-col>
      </v-row>
      <v-card-text>
        <v-divider/>
      </v-card-text>

      <v-card-text>
        <v-row align="center" justify="center">
          <v-col class="text-center text-md-start" cols="12" lg="7" md="3">
            <v-btn v-for="icon in icons" :key="icon" class="mx-4 white--text" icon>
              <v-icon color="secondary" size="40px">
                {{ icon }}
              </v-icon>
            </v-btn>
          </v-col>
          <v-col class="text-center text-md-end" cols="12" lg="2" md="3">
            <v-btn :to="{name: 'terms-of-service'}" text>
              {{ $vuetify.lang.t('$vuetify.general.footer.termsAndConditions') }}
            </v-btn>
          </v-col>
          <v-col class="text-center text-md-end" cols="12" lg="1" md="3">
            <v-btn :to="{name: 'privacy-policy'}" text>
              {{ $vuetify.lang.t('$vuetify.general.footer.privacy') }}
            </v-btn>
          </v-col>
          <v-col cols="12" lg="2" md="3">
            <v-card-text class="white--text mb-5 mb-md-0">
              <v-icon x-small>mdi-copyright</v-icon>
              {{ new Date().getFullYear() }} FooD. Laaqiq
            </v-card-text>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'KurccFooter',
  components: {
    KurccBrand: () => import('@/modules/app/components/KurccBrand')
  },
  computed: {
    iconSize () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 100
        case 'sm':
          return 60
        case 'md':
          return 100
        case 'lg':
          return 100
        case 'xl':
          return 100
        default:
          return 100
      }
    }
  },
  data () {
    return {
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-instagram'
      ]
    }
  }
}
</script>
